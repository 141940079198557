import React, { useLayoutEffect, useState } from 'react';
import { Tag, message } from 'antd';
import { STATUS_USER } from '@/config/AppConst';

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

export const convertStatusUser = (status) => {
  switch (status) {
    case STATUS_USER.NEW: {
      return (
        <Tag className="user-status is-new" key={STATUS_USER.ACTIVE}>
          Mới
        </Tag>
      );
    }
    case STATUS_USER.ACTIVE: {
      return (
        <Tag className="user-status is-active" key={STATUS_USER.ACTIVE}>
          Kích hoạt
        </Tag>
      );
    }
    case STATUS_USER.INACTIVE: {
      return (
        <Tag className="user-status is-disabled" key={STATUS_USER.INACTIVE}>
          Vô hiệu hóa
        </Tag>
      );
    }
    default: {
      return (
        <Tag className="user-status is-new" key={STATUS_USER.NEW}>
          Mới
        </Tag>
      );
    }
  }
};

export const beforeUploadImage = (file) => {
  const isJpgOrPng =
    file.type === 'image/jpeg' ||
    file.type === 'image/png' ||
    file.type === 'image/jpg';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

export const formatPhoneNumber = (phoneNumber) => {
  let formattedPhoneNumber;
  if (phoneNumber?.length === 10) {
    formattedPhoneNumber =
      phoneNumber.slice(0, 3) +
      ' ' +
      phoneNumber.slice(3, 7) +
      ' ' +
      phoneNumber.slice(7);
  } else if (phoneNumber?.length === 11) {
    formattedPhoneNumber =
      phoneNumber.slice(0, 4) +
      ' ' +
      phoneNumber.slice(4, 8) +
      ' ' +
      phoneNumber.slice(8);
  } else {
    formattedPhoneNumber = phoneNumber;
  }
  return formattedPhoneNumber;
};

export const convertPriceShopee = (value) => {
  if (value) {
    let price = parseInt(value) / 100000;

    return price.toLocaleString('vi-VN', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  } else {
    return 0;
  }
};

export const convertLinkImageShopee = (imageUrl) => {
  if (imageUrl) {
    return `https://down-bs-vn.img.susercontent.com/${imageUrl}.webp`;
  } else {
    return '';
  }
};
