/* eslint-disable no-unused-vars */
import axios from 'axios';

import { AuthUtil } from '@utils';
import { toast } from 'react-toastify';
import { actions } from '@/store/slices/Auth';
import store from '@/store';

const http = axios.create({
  timeout: 1000 * 60 * 5, // request timeout
  withCredentials: false,
});

let countShowMessage = 0;
http.interceptors.request.use(
  (config) => {
    const token =
      store.getState()?.auth?.currentUser?.access_token || AuthUtil.getToken();

    if (token && !config?.isPublic) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

http.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    const errorCode = error.response?.status;

    // Error 401 call refresh token
    if (errorCode === 401) {
      countShowMessage++;

      if (countShowMessage === 1) {
        toast.error(
          'Phiên hiện tại của bạn đã hết hạn. Vui lòng đăng nhập lại',
          {
            onClose: () => {
              countShowMessage = 0;
            },
          },
        );
      }
      AuthUtil.removeRefreshToken();
      AuthUtil.removeToken();
      store.dispatch(actions.removeUser());
    }

    // Handle error 500, 422... if necessary
    switch (errorCode) {
      case 400:
        error.response.data?.error
          ? toast.error(error.response.data?.error)
          : null;
        return error.response.data;
      case 404:
        toast.error('Không tìm thấy tài nguyên');
        return;
      case 500:
        toast.error(error?.response?.data?.message || 'Lỗi hệ thống');
        return;
    }

    return Promise.reject(error);
  },
);

export default http;
