/* eslint-disable prettier/prettier */
export const AuthApi = {
  register: '',
  login: '/api/auth/login',
  active: '',
  forgotPassword: '',
  resetPassword: '',
  changePassword: '',
  logout: '',
};

export const UserApi = {
  detailById: (id) => `/api/user/get/${id}`,
};

export const ProductApi = {
  getDetail: (item_id) => `/api/product/detail/${item_id}`,
  getList: '/api/product/getAll',
};

export const EmailApi = {
  sendEmailSubscribe: '/api/mail/sendEmailSubscribe',
  contactRequest: '/api/mail/contactRequest',
};

export const ConfigApi = {
  getInfoCompany: '/api/config/getInfoCompany',
};

export const BlogApi = {
  getDetail: (id) => `/api/blog/detail/${id}`,
  getList: '/api/blog/getAll',
  update: '/api/blog/update',
  create: '/api/blog/create',
};
