/* eslint-disable no-unused-vars */
import CommonButton from '@/components/Button';
import CommonInput from '@/components/Input/CommonInput';
import { REGEX_APP, TYPE_SEND_EMAIL } from '@/config/AppConst';
import { EmailService } from '@/services';
import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const Subscribe = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [formSave] = Form.useForm();

  const validation = {
    email: [
      {
        required: true,
        message: 'Nhập email đăng ký',
      },
      {
        pattern: REGEX_APP.REGEX_EMAIL,
        message: 'Email không hợp lệ',
      },
    ],
  };

  const handleSubscribe = async () => {
    try {
      setLoading(true);
      const object = {
        mail: formSave.getFieldValue('email')?.trim(),
        subject: 'Subscribe Xinhxiu',
        type: TYPE_SEND_EMAIL.SUBSCRIBE,
      };
      const res = await EmailService.sendEmailSubscribe(object);
      if (res?.success) {
        toast.success(
          'Đăng ký nhận thông tin thành công. Một email đã được gửi tới email của bạn.',
        );
        formSave.setFieldsValue({ email: '' });
      } else {
        toast.error(res?.msg);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    formSave.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className="section-subscribe">
      <div className="uk-container">
        <div className="subscribe-box">
          <div
            className="uk-grid uk-flex-middle uk-child-width-1-2@m"
            data-uk-grid>
            <div>
              <div className="section-title">
                <span>
                  Gửi TIN TỨC VÀ ƯU ĐÃI MỚI NHẤT TRỰC TIẾP VÀO HỘP THƯ CỦA BẠN
                </span>
                <div className="uk-h2">ĐĂNG KÝ NHẬN THÔNG TIN</div>
              </div>
            </div>
            <div>
              <div className="subscribe-box__form">
                <Form
                  layout="vertical"
                  initialValues={{}}
                  form={formSave}
                  onFinish={handleSubscribe}
                  name="formSave"
                  autoComplete="off"
                  className="w-100">
                  <div className="uk-flex uk-flex-middle">
                    <Form.Item
                      className="m-0 w-100"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      name="email"
                      label=""
                      rules={validation.email}>
                      <CommonInput
                        classNameProps="uk-input"
                        placeholder="Nhập email ...."
                        maxLength={100}
                        onChange={(e) => {
                          if (e?.target?.value) {
                            formSave.setFieldsValue({
                              email: e?.target?.value,
                            });
                          }
                        }}
                      />
                    </Form.Item>

                    <CommonButton
                      classNameProps="uk-button uk-button-danger"
                      title="Subscribe"
                      typeProps={{
                        htmlType: 'submit',
                        type: 'primary',
                      }}
                      loading={loading}
                      onClick={() => {}}
                    />
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Subscribe);
