import React from 'react';
import { Input } from 'antd';
import { VALIDATE_FORM } from '@/config/AppConst';

export default function CommonInput(props) {
  const {
    id,
    classNameProps,
    label,
    placeholder,
    value,
    disabled,
    defaultValue,
    allowClear,
    maxLength,
    prefix,
    suffix,
    onPaste,
    onChange,
    onKeyPress,
    onPressEnter,
    typeProps,
    styleProps,
    onBlur,
    autoComplete,
  } = props;

  const handleBlur =
    typeof onBlur === 'function' ? (e) => onBlur(e) : undefined;
  const handlePressEnter =
    typeof onPressEnter === 'function' ? (e) => onPressEnter(e) : undefined;
  const handleKeyPress =
    typeof onKeyPress === 'function' ? (e) => onKeyPress(e) : undefined;
  const handlePaste =
    typeof onPaste === 'function' ? (e) => onPaste(e) : undefined;
  const handleChange =
    typeof onChange === 'function' ? (e) => onChange(e) : undefined;

  return (
    <Input
      {...typeProps}
      style={styleProps}
      id={id}
      className={classNameProps}
      label={label}
      placeholder={placeholder}
      value={value}
      autoComplete={autoComplete}
      disabled={disabled}
      defaultValue={defaultValue}
      allowClear={allowClear}
      onPaste={handlePaste}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      onPressEnter={handlePressEnter}
      prefix={prefix}
      suffix={suffix}
      onBlur={handleBlur}
      maxLength={maxLength || VALIDATE_FORM.MAX_LENGTH_INPUT}
    />
  );
}
