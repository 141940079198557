import LoadableComponent from '@components/Loadable';
import { PAGE_URL } from '@/config/AppConst';

// When user is logged, user go to page has restrict = true app auto redirect to Home Page
// Using restrict = false. Keep this page.
export const PublicRoutes = [
  {
    path: '/',
    title: 'xinhxiu',
    component: LoadableComponent(() => import('@/screens/Homepage')),
  },
  {
    path: PAGE_URL.HOME,
    title: 'xinhxiu',
    component: LoadableComponent(() => import('@/screens/Homepage')),
  },
  {
    path: PAGE_URL.ABOUT,
    title: 'Giới thiệu',
    component: LoadableComponent(() => import('@screens/About')),
  },
  {
    path: PAGE_URL.CATEGORY,
    title: 'Shop',
    component: LoadableComponent(() => import('@screens/Category')),
  },
  {
    path: PAGE_URL.BLOG,
    title: 'Bài viết',
    component: LoadableComponent(() => import('@screens/Blog')),
  },
  {
    path: PAGE_URL.BLOG_DETAIL,
    title: 'Chi tiết bài viết',
    component: LoadableComponent(() => import('@/screens/Blog/DetailBlog')),
  },
  {
    path: PAGE_URL.CONTACT,
    title: 'Liên hệ',
    component: LoadableComponent(() => import('@screens/Contact')),
  },
  {
    path: PAGE_URL.DETAIL,
    title: 'Chi tiết sản phẩm',
    component: LoadableComponent(() => import('@screens/Detail')),
  },
  {
    path: PAGE_URL.POLICY,
    title: 'Điều khoản - Chính sách',
    component: LoadableComponent(() => import('@screens/Policy')),
  },
];

export default [...PublicRoutes];
