import AllPageRoutes from '@/router/router.config';
import { toast } from 'react-toastify';

const self = {};

self.getCurrentRoute = (path = '') => {
  if (path === '/') path = '';

  const currentPath = (AllPageRoutes || []).find(
    (route) => route?.path?.toLowerCase() === path.toLowerCase(),
  );

  return currentPath;
};

self.getPageTitle = (path = '') => {
  return self.getCurrentRoute(path)?.title ?? 'xinhxiu';
};

self.formatCurrencyDecimal = (
  number,
  decimal = 2,
  ignoreFormatInteger = false,
) => {
  if (!number && number !== 0) {
    return '-';
  }

  let [integerValue, decimalValue] = number.toString().split('.');
  decimalValue = (decimalValue || '').padEnd(decimal, '0');
  decimalValue = decimalValue.slice(0, decimal);

  if (!ignoreFormatInteger) {
    integerValue = (
      Number(integerValue?.replaceAll(',', '')) || 0
    ).toLocaleString('en');
  }

  if (decimal === 0) {
    return `${integerValue}`;
  }
  return `${integerValue}.${decimalValue}`;
};

self.truncateText = (text, length) => {
  if (!text) return '';

  const textSize = text.toString().length;
  if (textSize < length) return text;

  const size = Math.floor(length / 2);

  return `${text.slice(0, size)}.....${text.slice(
    textSize - size - 1,
    textSize,
  )}`;
};

self.copyText = (text, mess) => {
  if (!text) return;

  navigator.clipboard.writeText(text);
  toast.success(mess || 'Copy thành công');
};

self.handleApiFail = (error) => {
  const errorCode = error.response?.status;

  if (errorCode === 401) return;

  toast.error(error?.response?.data?.message || 'Có lỗi xảy ra');
};

export default self;
