import { PAGE_URL } from '@/config/AppConst';
import { selectConfig } from '@/store/slices';
import { formatPhoneNumber } from '@/utils/helper';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppUtil } from '@/utils';
import { BlogService } from '@/services';
import moment from 'moment';
import { Tooltip } from 'antd';

const Footer = () => {
  const { currentConfig } = useSelector(selectConfig);
  const navigate = useNavigate();
  const [listBlog, setListBlog] = useState([]);

  const getListBlog = async () => {
    const object = {
      page: 1,
      size: 3,
    };
    try {
      const response = await BlogService.getListBlog(object);
      if (response?.success) {
        setListBlog(response.data.data);
      } else {
        setListBlog();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getListBlog();
  }, []);

  return (
    <footer className="page-footer">
      <div className="page-footer__top">
        <div className="uk-container">
          <div
            className="uk-grid uk-grid-small uk-child-width-1-4@l uk-child-width-1-2@s"
            data-uk-grid>
            <div>
              <div className="uk-margin-medium">
                <div className="logo">
                  <a className="logo__link" href="index.html">
                    <div className="logo__icon">
                      <img src="assets/img/logo.png" alt="xinhxiu" />
                    </div>
                    <div className="logo__text">xinhxiu</div>
                  </a>
                </div>
              </div>
              <div className="uk-margin-medium">
                <p>{currentConfig?.aboutFooter}</p>
              </div>
              <div className="uk-margin-medium">
                <div className="support">
                  <a className="support__link" href="javascript:void(0)">
                    <div className="support__icon">
                      <i className="fas fa-headset" />
                    </div>
                    <div className="support__desc">
                      <div className="support__label">SMS/Zalo</div>
                      <div
                        className="support__phone"
                        onClick={() =>
                          AppUtil.copyText(
                            currentConfig?.phone,
                            'Copy số điện thoại thành công',
                          )
                        }>
                        {currentConfig?.phone &&
                          formatPhoneNumber(currentConfig?.phone)}
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="uk-margin-medium">
                <div className="social">
                  <ul className="social-list">
                    {currentConfig?.twitter && (
                      <li className="social-list__item">
                        <a
                          className="social-list__link"
                          href={currentConfig?.twitter}
                          target="_blank"
                          rel="noreferrer">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                    )}
                    {currentConfig?.facebook && (
                      <li className="social-list__item">
                        <a
                          className="social-list__link"
                          href={currentConfig?.facebook}
                          target="_blank"
                          rel="noreferrer">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                    )}
                    {currentConfig?.email && (
                      <li className="social-list__item">
                        <a
                          className="social-list__link"
                          href={`mailto:${currentConfig?.email}`}
                          target="_blank"
                          rel="noreferrer">
                          <i className="fab fa-google-plus-g" />
                        </a>
                      </li>
                    )}
                    {currentConfig?.youtube && (
                      <li className="social-list__item">
                        <a
                          className="social-list__link"
                          href={currentConfig?.youtube}
                          target="_blank"
                          rel="noreferrer">
                          <i className="fab fa-youtube" />
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <div className="page-footer__column page-footer__menu">
                <div className="uk-h4">Giới thiệu</div>
                <ul className="list-decor">
                  <li>
                    <a
                      href="javascript:void(0)"
                      onClick={() => navigate(`${PAGE_URL.ABOUT}`, {})}>
                      Về chúng tôi
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      onClick={() => navigate(`${PAGE_URL.POLICY}`, {})}>
                      Điều khoản & Chính sách
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => navigate(`${PAGE_URL.CONTACT}`, {})}
                      href="javascript:void(0)">
                      Liên hệ
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <div className="page-footer__column page-footer__news">
                <div className="uk-h4">Bài mới</div>
                <ul className="latest-news-list">
                  {listBlog?.map((item, index) => {
                    return (
                      <li key={index}>
                        <div className="latest-news-item">
                          <div className="latest-news-item__thumb">
                            <img
                              crossOrigin="anonymous"
                              src={process.env.REACT_APP_BASE + item.image}
                              alt={item.title}
                            />
                          </div>
                          <div className="latest-news-item__info">
                            <Tooltip title={item.title}>
                              <a
                                className="latest-news-item__title truncate-line"
                                href="javascript:void(0)">
                                {item.title}
                              </a>
                            </Tooltip>

                            <div className="latest-news-item__date">
                              {moment(item.createdAt).format('DD-MM-YYYY')}
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div>
              <div className="page-footer__column page-footer__info">
                <div className="uk-h4">Giờ làm việc</div>
                <ul className="info-list">
                  <li>
                    <div className="info-list-item">
                      <div className="info-list-item__title">Đặt hàng</div>
                      <div className="info-list-item__value">
                        Thứ 2 - Chủ nhật
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="info-list-item">
                      <div className="info-list-item__title">Dịch vụ</div>
                      <div className="info-list-item__value">
                        Thứ 2 - Thứ 6: <br /> 09:00 am to 09:00 pm
                      </div>
                    </div>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.dmca.com/Protection/Status.aspx?ID=af647c5d-c7e5-4312-aa4f-52eb3b91f770&refurl=https://xinhxiu.com/"
                      title="DMCA.com Protection Status"
                      className="dmca-badge"
                      rel="noreferrer">
                      <img
                        src="https://images.dmca.com/Badges/dmca_protected_1_120.png?ID=af647c5d-c7e5-4312-aa4f-52eb3b91f770"
                        alt="DMCA.com Protection Status"
                      />
                    </a>
                    <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"></script>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-footer__bottom">
        <div className="uk-container">
          <div className="uk-text-center">{currentConfig?.copyright}</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
