import { EmailApi } from '@/config/ApiEndpoints';
import http from '../Http';

export const sendEmailSubscribe = (object) => {
  return http.post(EmailApi.sendEmailSubscribe, object);
};

export const contactRequest = (object) => {
  return http.post(EmailApi.contactRequest, object);
};

export default {
  sendEmailSubscribe,
  contactRequest,
};
