import { UserApi } from '@/config/ApiEndpoints';
import http from '../Http';

export const getUserDetailById = (id) => {
  return http.get(UserApi.detailById(id));
};

export default {
  getUserDetailById,
};
