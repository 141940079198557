import * as React from 'react';

const NotFound = () => {
  return (
    <div className="not-found-page d-flex justify-content-center">
      <img src="assets/img/error-404.png" alt="xinhxiu" />
    </div>
  );
};

export default NotFound;
