import { ConfigApi } from '@/config/ApiEndpoints';
import http from '../Http';

export const getInfoCompany = () => {
  return http.get(ConfigApi.getInfoCompany);
};

export default {
  getInfoCompany,
};
