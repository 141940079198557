import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PAGE_URL } from '@/config/AppConst';
import {
  CloseOutlined,
  MenuFoldOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Drawer } from 'antd';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState(false);

  const handleOpenMenu = () => {
    setOpenMenu(!openMenu);
  };

  useEffect(() => {}, []);

  return (
    <>
      <header className="page-header page-header-transparent">
        <div className="page-header__inner">
          <div className="page-header__left">
            <div className="logo">
              <div
                className="logo__link pointer"
                onClick={() => navigate(`${PAGE_URL.HOME}`, {})}>
                <div className="logo__icon">
                  <img width="120" src="assets/img/logo.png" alt="xinhxiu" />
                </div>
                <div className="logo__text">xinhxiu</div>
              </div>
            </div>
          </div>
          <div className="page-header__center">
            <nav className="page-nav" data-uk-navbar>
              <ul className="uk-navbar-nav">
                <li>
                  <a
                    className={
                      location?.pathname === PAGE_URL.HOME ? 'active' : ''
                    }
                    onClick={() => navigate(`${PAGE_URL.HOME}`, {})}>
                    Trang chủ
                  </a>
                </li>
                <li>
                  <a
                    className={
                      location?.pathname === PAGE_URL.ABOUT ? 'active' : ''
                    }
                    onClick={() => navigate(`${PAGE_URL.ABOUT}`, {})}>
                    Giới thiệu
                  </a>
                </li>
                <li>
                  <a
                    className={
                      location?.pathname === PAGE_URL.CATEGORY ? 'active' : ''
                    }
                    onClick={() => navigate(`${PAGE_URL.CATEGORY}`, {})}>
                    Shop
                  </a>
                </li>
                <li>
                  <a
                    className={
                      location?.pathname === PAGE_URL.BLOG ? 'active' : ''
                    }
                    onClick={() => navigate(`${PAGE_URL.BLOG}`, {})}>
                    Bài viết
                  </a>
                </li>
                <li>
                  <a
                    className={
                      location?.pathname === PAGE_URL.CONTACT ? 'active' : ''
                    }
                    onClick={() => navigate(`${PAGE_URL.CONTACT}`, {})}>
                    Liên hệ
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="page-header__right">
            <div
              className="uk-navbar-toggle search-btn pointer"
              onClick={() => {
                document.getElementById('modal-search').style.display = 'block';
              }}>
              <SearchOutlined />
            </div>
            <div className="uk-navbar-toggle cart-btn pointer">
              {/* <div className="cart-btn__icon uk-icon"> */}
              {/* <UserOutlined /> */}
              <w3m-button></w3m-button>
              {/* </div> */}
            </div>
            <div
              className="uk-navbar-toggle menu-btn pointer"
              onClick={() => {
                setOpenMenu(true);
              }}>
              <MenuFoldOutlined style={{ fontSize: '22px' }} />
            </div>
          </div>
        </div>
        <Drawer title="" onClose={handleOpenMenu} open={openMenu}>
          <div className="mb-3 pointer">Đăng nhập</div>
          <div className="mb-3 pointer">Đăng ký</div>
          <div className="mb-3 pointer">Tool</div>
        </Drawer>
      </header>

      <div className="uk-modal-full uk-modal uk-open" id="modal-search">
        <div
          className="uk-modal-dialog uk-flex uk-flex-center uk-flex-middle"
          data-uk-height-viewport>
          <button
            className="uk-modal-close-full"
            type="button"
            onClick={() => {
              document.getElementById('modal-search').style.display = 'none';
            }}>
            <CloseOutlined />
          </button>
          <form className="uk-search uk-search-large">
            <input
              className="uk-search-input uk-text-center"
              type="search"
              placeholder="Tìm kiếm..."
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default React.memo(Header);
