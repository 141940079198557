import { ProductApi } from '@/config/ApiEndpoints';
import http from '../Http';

export const getDetailProduct = (itemId) => {
  return http.get(ProductApi.getDetail(itemId));
};

export const getListProduct = (object) => {
  return http.post(ProductApi.getList, object);
};

export default {
  getDetailProduct,
  getListProduct,
};
